import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">BIZ-Office</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink className="nav-link" to="/">
              Главная
            </NavLink>
            <NavLink className="nav-link" to="contacts">
              Контакты
            </NavLink>
            <NavLink className="nav-link" to="pricing">
              Цены
            </NavLink>
          </Nav>
          <Nav>
            <NavLink to="login" className="nav-link">
              Вход
            </NavLink>
            <NavLink to="registration" className="nav-link">
              Регистрация
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
