import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [validated, setValidated] = useState(false);
  const [log, setLog] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [isCheck, setCheck] = useState(false);

  const domain = window.location.origin;
  console.log(domain);
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const formJson = JSON.stringify(Object.fromEntries(formData));

    setLoading(true);

    fetch(domain + "/api/user/login", {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: formJson,
    })
      .then((response) => response.json())
      .then((json) => {
        const { status, data, error } = json;

        if (status === 1) {
          setLog("");
        } else {
          setLog(error);
        }
        setLoading(false);
      });

    setValidated(true);
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-5">Вход в панель управления</h1>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="block_form">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>Имя</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Введите email"
                    name="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    Введите электронную почту
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Пароль</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Введите пароль"
                    name="password"
                  />
                  <Form.Control.Feedback type="invalid">
                    Введите пароль
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Button
                    type="submit"
                    variant="light"
                    disabled={isLoading}
                    className="btn_form"
                  >
                    <FontAwesomeIcon
                      className="icon_btn"
                      icon={faRightToBracket}
                    />
                    {isLoading ? "Загрузка..." : "Вход"}
                  </Button>
                </div>
              </div>
            </Form>
            <div className="row">
              <div className="col-md-12 log_form">{log}</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <a>Забыли пароль?</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </Container>
  );
}

export default Login;
