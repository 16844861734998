import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function Home() {
  {
    const domain = window.location.origin;
    fetch(domain + "/api/user/tokenData", {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //body: formJson,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      });
  }

  return <>Home</>;
}

export default Home;
