import "./App.css";
import "./fonts/Inter/stylesheet.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Layout from "./layout/layout";
import Home from "./components/main/Pages/Home";
import Contacts from "./components/main/Pages/Contacts";
import Pricing from "./components/main/Pages/Pricing";
import Login from "./components/main/Pages/Login";
import Registration from "./components/main/Pages/Registration";
import NotFound from "./layout/NotFound";

function App() {
  return (
    <div className="App">
      {
        //https://ru.hexlet.io/blog/posts/react-router-v6
        //https://react-bootstrap.github.io/docs/components/accordion
      }

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
